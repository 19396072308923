<script>
export default {
  data() {
    return {
      disabledDate: (date) => {
        return date > new Date();
      },
      numberRules: [
        (v) => !!v || "請填寫發票號碼",
        (v) => /^[a-zA-Z]{2}[0-9]{8}$/.test(v) || "輸入格式為英文2碼數字8碼",
      ],
      codeRules: [
        (v) => !!v || "請填寫發票隨機碼",
        (v) => /^\d{4}$/.test(v) || "輸入格式為4碼數字",
      ],
    };
  },
  computed: {
    todayDate() {
      const today = new Date();
      const y = today.getFullYear();
      const m = today.getMonth() + 1;
      const d = today.getDate();
      return `${y}-${m < 10 ? "0" + m : m}-${d < 10 ? "0" + d : d}`;
    },
  },
  mounted() {
    this.date = this.todayDate;
  },
  methods: {},
};
</script>